<template>
    <div>
        <v-container class="my-5">
            <v-row>
                <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="date"
                    persistent
                    width="350px"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-model="date"
                            readonly
                            v-on="on"
                            color="indigo"
                            dark
                            class="mr-5 ml-3 mb-4"
                        >
                            {{ date }}
                        </v-btn>
                    </template>

                    <v-date-picker
                        v-model="date"
                        scrollable
                        type="month"
                        @change="dateChange(date)"
                    >
                        <v-spacer></v-spacer>

                        <v-btn text color="primary" @click="modal = false">
                            Anuluj
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-row>

            <v-row>
                <v-col>
                    <v-card class="mx-auto">
                        <v-card-title>{{ calcFullRate }} zł</v-card-title>
                        <v-card-subtitle class="text-start"
                            >Twoje przychody</v-card-subtitle
                        >
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import io from "socket.io-client";

export default {
    data() {
        return {
            tasks: [],
            socket: io(this.url),
            date: this.$route.params.month,
            id: this.$route.params.id,
            modal: false,
            tutorCost: 0,
            tutor: "",
        };
    },
    methods: {
        getAllAppretices() {
            this.$axios
                .get(`${this.url}api/apprentice/payment/${this.date}`)
                .then(
                    (el) =>
                        (this.tutorCost =
                            (parseFloat(el.data.charactersSum) / 1000) * 2)
                );
        },
        getTutor() {
            this.$axios
                .get(`${this.url}api/users/practice-tutor`)
                .then((response) => {
                    this.$axios
                        .get(
                            `${this.url}api/apprentice/current-tutor/${response.data.email}`
                        )
                        .then((el) => {
                            if (el.data) {
                                if (el.data.tutor) {
                                    this.tutor = el.data.tutor.email;
                                }
                            }
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getPosts() {
            this.$axios
                .get(
                    `${this.url}api/tasks/payment/korektor/${
                        this.date
                    }/${localStorage.getItem("id")}`
                )
                .then((resp) => {
                    this.tasks = resp.data;
                });
        },
        // New
        dateChange(date) {
            this.$router.push(
                `../../../korektor/payments/${date}/${localStorage.getItem(
                    "id"
                )}`
            );
            this.getPosts();
            this.getAllAppretices();
        },
    },
    created() {
        this.getPosts();
        this.getAllAppretices();
        this.getTutor();
        this.socket.on("newTaskAdded", () => {
            this.getPosts();
        });

        this.socket.on("taskPaidUpdate", () => {
            this.getPosts();
        });

        this.socket.on("taskDeleted", () => {
            this.getPosts();
        });
        this.socket.on("newTaskAprrenticeEdited", () => {
            this.getPosts();
            this.getAllAppretices();
        });
        this.socket.on("appreticeChangedStatus", () => {
            this.getPosts();
            this.getAllAppretices();
        });
    },
    computed: {
        calcFullRate() {
            let allTasks = this.tasks;
            let result = [];
            result = allTasks.reduce(function (previousValue, currentValue) {
                console.log(currentValue.corectorRate);
                if (currentValue.corectorRate) {
                    return previousValue + currentValue.corectorRate;
                } else {
                    return previousValue + currentValue.characters / 1000;
                }
            }, 0);
            return this.tutor == this.email
                ? (result + this.tutorCost).toFixed(2)
                : result.toFixed(2);
        },
        email() {
            return this.$store.getters.getUserMail;
        },
    },
};
</script>
