<template>
	<div>
		<Payments/>
	</div>
</template>

<script>
import Payments from '@/components/templates/KorektorPayments.vue'
export default {
	components: {
		Payments
	}
}
</script>